import { MAINTAIN_PERMISSION, GROWERS_BARCODEREPRINT } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "p-5 barcode-reprint"
  }, [_c("div", {
    staticClass: "p-3"
  }, [_c("div", {
    staticClass: "mb-3 d-flex justify-content-between"
  }, [_c("div", {
    staticClass: "h6"
  }, [_vm._v("Reprint Labels for an Intake")]), _c("div", {
    staticClass: "d-flex align-items-center"
  }, [_c("span", {
    staticClass: "mr-3"
  }, [_vm._v("Reprint all")]), _c("switch-input", {
    attrs: {
      "value": _vm.isReprintAll,
      "disabled": !_vm.hasMaintainPermission
    },
    on: {
      "change": _vm.onSwitchChange
    }
  })], 1)]), _c("simple-form", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c("a-row", {
          attrs: {
            "gutter": 24
          }
        }, [_c("a-col", {
          attrs: {
            "span": 6
          }
        }, [_c("select-input", {
          attrs: {
            "placeholder": "Select Intake Code",
            "form-item": "",
            "label": "Intake Code",
            "reference": "grower.intakes",
            "source": "id",
            "source-label": "intakeNumber",
            "lazy": "",
            "custom-search": "",
            "disabled": !_vm.hasMaintainPermission,
            "rules": "required"
          },
          on: {
            "search": _vm.onSearchIntake,
            "change": _vm.onIntakeCodeChange
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 6
          }
        }, [_c("text-input", {
          attrs: {
            "form-item": "",
            "label": "Starting Bin Number",
            "placeholder": "Enter the 9 digit number",
            "rules": _vm.isRequired,
            "disabled": _vm.isReprintAll,
            "value": _vm.startBinNumber
          },
          on: {
            "change": _vm.onStartingNumChange
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 6
          }
        }, [_c("text-input", {
          attrs: {
            "form-item": "",
            "label": "Ending Bin Number",
            "placeholder": "Enter the 9 digit number",
            "rules": _vm.isRequired,
            "disabled": _vm.isReprintAll,
            "value": _vm.endBinNumber
          },
          on: {
            "change": _vm.onEndingNumChange
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 6
          }
        }, [_c("select-input", {
          attrs: {
            "lazy": "",
            "placeholder": "Select Printer",
            "form-item": "",
            "reference": "grower.printers",
            "source": "id",
            "source-label": "name",
            "label": "Select Printer",
            "disabled": !_vm.hasMaintainPermission,
            "rules": "required"
          },
          on: {
            "change": _vm.onPrinterChange
          }
        })], 1)], 1), _c("div", {
          staticClass: "ant-modal-footer p-3"
        }, [_c("a-button", {
          attrs: {
            "loading": _vm.isSubmitting,
            "type": "primary",
            "disabled": !_vm.hasMaintainPermission
          },
          on: {
            "click": function($event) {
              return handleSubmit(_vm.onSubmitReprint);
            }
          }
        }, [_c("span", [_vm._v("Reprint")])])], 1)];
      }
    }])
  })], 1)]);
};
var staticRenderFns$1 = [];
var BarcodeReprint_vue_vue_type_style_index_0_lang = "";
const __vue2_script$1 = {
  name: "BarcodeReprint",
  inject: ["resourceProps", "can"],
  data() {
    const [intakeProps] = this.resourceProps;
    return {
      intakeProps,
      isReprintAll: true,
      isRequired: "",
      isSubmitting: false,
      startBinNumber: "",
      endBinNumber: "",
      printerId: "",
      intakeId: ""
    };
  },
  computed: {
    hasMaintainPermission() {
      return this.can(MAINTAIN_PERMISSION);
    }
  },
  methods: {
    storeValue(resourceKey, value) {
      this.intakeProps.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    },
    onSearchIntake(value) {
      const querySearch = `fe=((IntakeNumber StartsWith \`${value}\`))`;
      this.intakeProps.crud.fetchList(`${this.intakeProps.apiUrl}/grower/intakes?${querySearch}`, void 0, false);
    },
    async onSubmitReprint() {
      this.isSubmitting = true;
      try {
        const { apiUrl: apiUrl2 } = this.intakeProps;
        const url = `${apiUrl2}/grower/intakes/${this.intakeId}/barcode-reprint`;
        await this.intakeProps.crud.submitEntity("create", url);
      } finally {
        this.isSubmitting = false;
      }
    },
    isRequiredInput() {
      if (this.isReprintAll === false) {
        this.isRequired = "required";
      } else {
        this.isRequired = "";
      }
    },
    onSwitchChange(value) {
      this.isReprintAll = value;
      this.startBinNumber = "";
      this.endBinNumber = "";
      this.storeValue("startBinNumber", "");
      this.storeValue("endBinNumber", "");
      this.isRequiredInput();
    },
    onStartingNumChange(value) {
      this.startBinNumber = value;
      this.storeValue("startBinNumber", value);
    },
    onEndingNumChange(value) {
      this.endBinNumber = value;
      this.storeValue("endBinNumber", value);
    },
    onPrinterChange(value) {
      this.printerId = value;
      this.storeValue("printerId", value);
    },
    onIntakeCodeChange(value) {
      this.intakeId = value;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var BarcodeReprint = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", {
    attrs: {
      "items": _vm.menuItems
    }
  }), _c("div", {
    staticClass: "h5 pl-5 pb-4 pt-2 barcode-heading"
  }, [_vm._v("Barcode Reprint")]), _c("resource", {
    attrs: {
      "name": ["grower.intakes", "grower.printers"],
      "api-url": _vm.apiUrl,
      "page": _vm.page
    }
  }, [_c("barcode-reprint")], 1)], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = "";
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    BarcodeReprint
  },
  data() {
    return {
      apiUrl,
      page: GROWERS_BARCODEREPRINT,
      menuItems: [
        {
          key: "intakeprocessing",
          title: "Intake Processing",
          path: ""
        },
        {
          key: "barcode-reprint",
          title: "Barcode Reprint",
          path: "/watties-grower/barcode-reprint"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
